<template>
    <b-row>
        <b-col cols="12">
            <b-overlay
                variant="white"
                :show="showLoading"
                spinner-variant="primary"
                blur="0"
                opacity=".75"
                rounded="sm"
            >
                <b-card v-if="intervention">
                    <b-row class="m-0">
                        <b-col v-if="intervention.equipment_id" cols="12" md="4">
                            <b-form-group label="Nº Equipo" label-for="">
                                <p>{{ intervention.equipment_id }}</p>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                            <b-form-group label="Urgente" label-for="">
                                <p>{{ intervention.urgent ? 'Si' : 'No' }}</p>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                            <b-form-group label="Nº Tag" label-for="">
                                <p>{{ intervention.tag.code }}</p>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                            <b-form-group label="Observaciones" label-for="">
                                <p>{{ intervention.description }}</p>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="12" lg="6" xl="3">
                            <b-form-group label="Estado" label-for="account-roles">
                                <v-select v-model="intervention.intervention_states_id" label="name" :options="states"
                                          :reduce="(option) => option.id">
                                    <template #no-options="{}">
                                        No hay estados.
                                    </template>
                                </v-select>
                            </b-form-group>
                            <small v-if="validation.urgent" class="text-danger w-100">
                                {{ validation.intervention_states_id }}
                            </small>
                        </b-col>
                    </b-row>
                    <b-row class="m-0">

                    </b-row>
                    <b-row class="m-0">
                        <b-col cols="12" sm="12" xl="12">
                            <b-form-group label="Solución del Técnico" label-for="">
                                <b-form-textarea v-model="intervention.technician_solution" type="text" placeholder="Observaciones"
                                                 autocomplete="off" />
                                <small v-if="validation.technician_solution" class="text-danger">
                                    {{ validation.description }}
                                </small>
                            </b-form-group>
                            <small v-if="validation.urgent" class="text-danger w-100">
                                {{ validation.intervention_states_id }}
                            </small>
                        </b-col>
                    </b-row>
                    <b-row class="m-0" align-h="start">
                        <b-col cols="12">
                            <b-button variant="primary" class="mt-2 mr-1" @click="updateData">
                                Actualizar Cambios
                            </b-button>
                            <b-button variant="outline-secondary" type="reset" class="mt-2" @click="cancel">
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-overlay>
        </b-col>
    </b-row>
</template>

<script>
import { BCard, BCol, BOverlay, BButton, BRow, BFormGroup, BFormTextarea } from 'bootstrap-vue'
import { workCenters } from "@/constants/workCenters"
import helpers from "@/resources/helpers/helpers"
import { baseUrl } from "@/constants/app"
import vSelect from "vue-select"
import { mapState } from "vuex"
import { createHelpers } from "vuex-map-fields"
import { INTERVENTIONS_LIST_EQUIPMENT } from "@/router/web/constants"

const { mapFields } = createHelpers({
    getterType: 'equipment/getField',
    mutationType: 'equipment/updateField',
})

export default {
    name: "ManageView",
    components: {
        BCol,
        BRow,
        BCard,
        BOverlay,
        BButton,
        BFormGroup,
        BFormTextarea,
        vSelect,
    },
    data() {
        return {
            urgenOptions: [
                {
                    id: true,
                    name: 'Urgente',
                },
                {
                    id: false,
                    name: 'No urgente',
                }
            ],
            showLoading: false,
            workCenter: [],
            baseUrl,
            INTERVENTIONS_LIST_EQUIPMENT,
            workCenters,
            helpers,
            states: [],
        }
    },
    computed: {
        ...mapState('intervention', ['validation']),
        ...mapState('intervention', ['intervention']),
        ...mapState('tag', ['tags']),
        ...mapFields({ equipment: 'equipment' }),
    },
    created() {
        this.getIntervention()
        this.getStates()
    },
    methods: {
        getIntervention() {
            this.showLoading = true

            this.$store.dispatch('intervention/getIntervention', {
                id: this.$route.params.id,
                relations: ['equipment', 'user', 'tag'],
            }).then(response => {
                this.showLoading = false
                this.equipment.id = response.equipment.id
            }).catch(error => {
                this.showLoading = false
                console.log(error)
                helpers.showToast(error.message, 'MonitorIcon', 'danger')
            })
        },
        getStates() {
            this.showLoading = true

            this.$store.dispatch('interventionStates/getStates').then(response => {
                this.states = response
                this.showLoading = false

            }).catch(error => {
                this.showLoading = false
                console.log(error)
                helpers.showToast(error.message, 'MonitorIcon', 'danger')
            })
        },
        updateData() {
            this.showLoading = true
            this.intervention.id = this.$route.params.id

            this.$store.dispatch('intervention/update', this.intervention)
                .then(response => {
                    this.showLoading = false
                    helpers.showToast('Intervención editada correctamente', 'CheckIcon', 'success')
                    this.$router.push({ path: INTERVENTIONS_LIST_EQUIPMENT + `/${this.equipment.id}/false` })
                })
                .catch(error => {
                    this.showLoading = false
                    console.log(error)
                })
        },
        cancel() {
            this.$router.push({ name: 'sheet_intervention', id: this.$route.params.id })
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
